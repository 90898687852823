import {codeSplit, Controller, FetchOptions, Result} from 'bernie-core';
import {PageData} from 'bernie-http';

// "controllers" map directly to react-router routes, can additionally provide data

export class Page1Controller implements Controller {
    public pageId = 'Page1';
    public path = '/page1';
    public routeName = 'page1';
    public bundles = [];

    /**
     * Components may be referred to directly
     *   `public component = ExamplePage;`
     *
     * Or, the preferred option is to create a code split point that will generate a new bundle.
     *   Use webpack's magic comments `webpackChunkName: "INSERT_FRIENDLY_NAME_HERE"`
     *   This will give it a friendly name for the name of the file.
     */
    public component = codeSplit(() => import(/* webpackChunkName: "page1" */ '../views/page1'));
    public exact = true;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public fetch(options: FetchOptions): Promise<Result> {
        return Promise.resolve({});
    }

    public fetchPageData(): Promise<PageData> {
        return Promise.resolve({title: 'Bernie Example Page 1'}) as Promise<PageData>;
    }
}

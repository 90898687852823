import React from 'react';
import {ScriptTag} from './ScriptTag';

interface PendoProps {
    publicUuid: string;
    host: string;
    apiKey: string;
    roles: string;
    agencyId: string;
    location: string;
}

export const Pendo = (props: PendoProps) => (
    <ScriptTag
        id={'pendo'}
        script={`
        if ('${props.publicUuid}' && '${props.host}') {
            (function(apiKey){
                (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
                v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                    o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                    y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
            })('${props.apiKey}');
                
            pendo.initialize({
                visitor: {
                    id: '${props.publicUuid}',
                    host: '${props.host}',
                    roles: '${props.roles}',
                    agencyId: '${props.agencyId}',
                    location: '${props.location}'
                },
                account: {
                    id: '${props.agencyId}'
                }
            });
        }
    `}
    />
);

export default Pendo;

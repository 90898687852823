import React from 'react';
import {ScriptTag} from './ScriptTag';

export interface EdapConfig {
    skipPageview: boolean;
    skipFlush: boolean;
    skipFeatureFlags: boolean;
    skipTagManager: boolean;
    skipGA: boolean;
}

export const EdapOptions: React.FC<Partial<EdapConfig>> = ({
    skipPageview = false,
    skipFlush = false,
    skipFeatureFlags = false,
    skipTagManager = false,
    skipGA = false
}: Partial<EdapConfig>) => {
    return (
        <ScriptTag
            id="edapOptions"
            script={`
        window['edapOptions'] = {
            skipPageview: ${skipPageview},
            skipFlush: ${skipFlush},
            skipFeatureFlags: ${skipFeatureFlags},
            skipTagManager: ${skipTagManager},
            skipGA: ${skipGA}
        };
    `}
        />
    );
};

export default EdapOptions;

import type {SiteContext} from '@catalyst/hapi-global-config-resolution';
import type {
    ServerStoreFactoryContext,
    StoreDependencies,
    PlatformData,
    SystemData,
    Agency,
    AgencyData,
    UserData,
    Segments
} from './index';
import type {SerializedData} from 'bernie-core';
import type {Request, ResponseToolkit} from '@hapi/hapi';
import type {ROLE_CHECK} from 'src/shared/constants';

import get from 'lodash/get';
import {Store} from 'bernie-plugin-mobx';

import {isAuthStrategyHomeaway} from '../server/utils/authUtil';
import {encode as encodeCookie} from '../universal/utils/multiValueCookie';
import {ADMIN_PANEL_ROLE, AGENCY_DOMAIN_COOKIE, FILTERS_ARRAY, SANITIZATION_REGEXES} from '../shared/constants';
const {FULL, ALLOW_KEYBOARD_SPECIAL_CHARS} = SANITIZATION_REGEXES;

/**
  deepAssign() acts much like Object.assign() except that it will merge nested objects.
  It will NOT merge arrays, the last source value will win with arrays.
  There are other differences... Object.assign() will use getters and setters, while
  deepAssign() just assigns the value directly.

  deepAssign() operates recursively, but is not a recursive function... it crawls the
  object trees and adds copy operations to a stack which is processed and added to in
  a loop until empty.
 */
function deepAssign<T>(target: Partial<T> = {}, ...sources: Partial<T>[]) {
    // this stack will hold target and source objects we need to combine...
    const stack: Array<{target: Partial<T>; sources: Array<Partial<T>>}> = [];
    // add our initial target and sources
    stack.push({target, sources});

    // loop through each operation we need to do
    while (stack.length) {
        const op = stack.pop();

        // get the unique keys from each source object we need to combine into target
        const keys = new Set<string>();
        op.sources.flatMap((s) => Object.keys(s)).forEach((k) => keys.add(k));

        // loop through each key
        for (const key of Array.from(keys)) {
            // determine if the key in any source is an object
            const keyIsObj = op.sources.some((s) => key in s && typeof s[key] === 'object' && !Array.isArray(s[key]));

            if (keyIsObj) {
                // for objects, initialize the target object (if necessary) and push a new operation onto the stack
                op.target[key] ??= {};
                stack.push({target: op.target[key], sources: op.sources.filter((s) => key in s).map((s) => s[key])});
            } else {
                // for non-objects, reduce the source values, initialized with the target value.
                op.target[key] = op.sources.reduce((agg, s) => (key in s ? s[key] : agg), op.target[key]);
            }
        }
    }

    return target;
}

// Use declaration merging to get typescript to merge properties of StoreDependencies into AssistantStore class
// This is safe ONLY because we assign all the props in the constructor from our StoreDependencies object...
// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface AssistantStore extends StoreDependencies {}
// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class AssistantStore extends Store {
    // serialized data fields
    system: Partial<SystemData>;
    platform: Partial<PlatformData>;
    translations: Record<string, string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters: any;

    getContext: () => Partial<ServerStoreFactoryContext>;

    isServerEnv() {
        return 'request' in this.getContext();
    }

    getRequest() {
        const {request} = this.getContext();
        return request?.extensions?.plugins?.['hapi-request-plugin'] as Request;
    }

    getResponseToolkit() {
        const {request} = this.getContext();
        return request?.extensions?.plugins?.['hapi-response-toolkit-plugin'] as ResponseToolkit;
    }

    constructor(
        state: SerializedData,
        context: Partial<ServerStoreFactoryContext>,
        dependencies: Partial<StoreDependencies> = {}
    ) {
        super(state, context.logger);
        this.getContext = () => context;

        // copy dependencies onto store object
        Object.assign(this, dependencies);
    }

    hydrate(data: SerializedData): void {
        Object.assign(this, data);
    }

    private logError(name: string) {
        return (err: Error) => this.getRequest().log(['error', 'AssistantStore', name], err);
    }

    public async setCookie(store: Partial<AssistantStore> = this) {
        if (!this.isServerEnv()) return;

        const {request} = this.getContext();
        const {escapiaRoles} = get(request, 'extensions.auth.artifacts.credentials.principal', {});

        const hapiRequest = this.getRequest();
        const h = this.getResponseToolkit();

        const agencyHost = (hapiRequest?.auth?.artifacts?.agency as Record<string, string>)?.hostname || '';

        if (agencyHost) {
            const isUberAdmin = Array.isArray(escapiaRoles) ? escapiaRoles.includes(ADMIN_PANEL_ROLE) : false;
            const isInternalLogin = hapiRequest.state.okta && isUberAdmin;
            const cookie: Record<string, string | boolean | number> = {
                domain: agencyHost,
                autoRedirect: true
            };
            // We don't want to set isInternal=false for non-internal user's
            // as the cookie isn't encrypted in any way and the user can easily
            // see and potentially change the isInternal value.
            // We also don't want to set isInternal=true unless the UberAdmin
            // logged in via okta so they won't be redirected to /internal/login
            // unless they already logged in that way.
            if (isInternalLogin) {
                cookie['isInternal'] = true;
                deepAssign(store, {platform: {userData: {isInternal: true}}});
            }
            if (h && 'state' in h) {
                h.state(AGENCY_DOMAIN_COOKIE, encodeCookie(cookie), {
                    ttl: 7 * 24 * 60 * 60 * 1000, // 1 week
                    domain: agencyHost.split('.').slice(1).join('.'),
                    isSecure: true,
                    isSameSite: 'Lax'
                });
            }
        }
    }

    public async fetchInitialState(): Promise<Partial<AssistantStore>> {
        if (!this.isServerEnv()) return {};
        const isGetAllAgencyData = isAuthStrategyHomeaway(this.getRequest());

        // temporary store for coordinating data population from promises
        const initialData: Partial<AssistantStore> = {};

        // array of promises to populate the initialData
        const promises = [
            this.setCookie(initialData), // promise to set the cookie
            this.reduceSystemData(initialData), // promise to set the system data
            this.reducePlatformData(initialData), // promise to set most of the platform data
            this.reduceTranslations(initialData), // promise to set the translations data
            this.reduceUserData(initialData), // promise to set the platform.userData

            // promises to set the platform.agencyData and possibly the platform.allAgencies data
            isGetAllAgencyData ? this.reduceAllAgencyData(initialData) : this.reduceAgencyData(initialData)
        ];

        await Promise.allSettled(promises); // wait for all the promises to finish

        // at this point initialData is completely populated...
        // so we copy the field values onto the store
        Object.assign(this, initialData);

        return initialData;
    }

    public async fetchSessionCheckResponse(): Promise<ROLE_CHECK> {
        if (!this.isServerEnv()) return undefined;
        return await this.getSessionCheck(this.getRequest());
    }

    public async fetchSystemData(callback: (data: Partial<SystemData>) => void = null): Promise<Partial<SystemData>> {
        if (!this.isServerEnv()) return {};

        const {request, config} = this.getContext();
        const plugins = request?.extensions?.plugins;
        const site: SiteContext = plugins['@catalyst/hapi-global-config-resolution'].site;
        const {deviceType} = plugins['@catalyst/hapi-device-info'];

        const result = {
            siteName: site.name,
            brandId: site.brandId,
            fullLocale: site.locale,
            locale: site.locale.match(/^.{2}_.{2}/)[0],
            environment: `${config.catalyst.server.app.vars.environment}`,
            assetRegistryUrl: `${config.catalyst.server.app.vars.assetRegistryUri}`,
            isMobile: deviceType === 'mobile',
            isTablet: deviceType === 'tablet',
            isDesktop: deviceType !== 'mobile' && deviceType !== 'tablet',
            appVersion: globalThis.process?.env?.ACTIVE_VERSION ?? '-1',
            sisenseBaseUrl: `${config.catalyst.server.app.vars.sisenseBaseUrl}`
        };

        if (callback) callback(result);
        return result;
    }

    public async reduceSystemData(store: Partial<AssistantStore> = this) {
        await this.fetchSystemData((systemData) => deepAssign(store, {system: systemData}));
    }

    public async fetchPlatformData(
        callback: (data: Partial<PlatformData>) => void = null
    ): Promise<Partial<PlatformData>> {
        if (!this.isServerEnv()) return {};

        const {config} = this.getContext();

        const result = {
            isLoginError: false,
            isLoginSuccessful: false,
            isSessionCheckInProgress: false,
            isSessionGood: false,
            isSessionCheckComplete: false,
            isSessionInvalid: false,
            featureFlags: {},
            isUserValidationInProgress: false,
            isMfaCodeValidationInProgress: false,
            mapKey: `${config?.catalyst?.server?.app?.vars?.geoServices?.googleMap?.API_Key || ''}`,
            menu: [],
            canUseGlobalSearch: false,
            isEmailUpdateRequired: false,
            breadcrumbs: {}
        };

        if (callback) callback(result);
        return result;
    }

    public async reducePlatformData(store: Partial<AssistantStore> = this) {
        await this.fetchPlatformData((platformData) => deepAssign(store, {platform: platformData})).catch(
            this.logError('platformData')
        );
    }

    public async fetchTranslations(
        callback: (data: Partial<Record<string, string>>) => void = null
    ): Promise<Record<string, string>> {
        if (!this.isServerEnv()) return {};
        const result = await this.getTranslations(this.getRequest());

        if (callback) callback(result);
        return result;
    }

    public async reduceTranslations(store: Partial<AssistantStore> = this) {
        await this.fetchTranslations((translations) => deepAssign(store, {translations})).catch(
            this.logError('translations')
        );
    }

    public async fetchUserData(
        callback: (data: Partial<UserData> | {redirectUrl: string}) => void = null
    ): Promise<Partial<UserData> | {redirectUrl: string}> {
        if (!this.isServerEnv()) return {};

        const result = await this.getUserData(this.getRequest());
        if (callback) callback(result);
        return result;
    }

    public async reduceUserData(store: Partial<AssistantStore> = this) {
        await this.fetchUserData((userData) => {
            if (!('redirectUrl' in userData)) {
                deepAssign(store, {platform: {userData}});
            }
        }).catch(this.logError('userData'));
    }

    public async fetchAgencyData(callback: (data: Partial<AgencyData>) => void = null): Promise<Partial<AgencyData>> {
        if (!this.isServerEnv()) return {};

        const result = await this.getAgencyData(this.getRequest());
        if (callback) callback(result);
        return result;
    }

    public async reduceAgencyData(store: Partial<AssistantStore> = this) {
        await this.fetchAgencyData((agency) => {
            const segments = this.reduceAgencySegments(agency);
            deepAssign(store, {platform: {agencyData: {...agency, segments}}});
        }).catch(this.logError('agencyData'));
    }

    public async fetchAllAgencyData(
        callback: (data: {agency: Partial<AgencyData>; getAllAgencies?: Array<Partial<Agency>>}) => void = null
    ): Promise<{
        agency: Partial<AgencyData>;
        getAllAgencies?: Array<Partial<Agency>>;
    }> {
        if (!this.isServerEnv()) return {agency: {}};

        const result = await this.getAllAgencyData(this.getRequest());
        if (callback) callback(result);
        return result;
    }

    // Agency segment data returned from graphql is not formatted for the UI, this converts the data
    // from a single array where each element can be any segment type to an object where each segment
    // type is it's own key, containing an array of strings for only that segment type.
    private reduceAgencySegments(agencyData: Partial<AgencyData>): Partial<Segments> {
        // ensure we have an array
        const segmentData = Array.isArray(agencyData.segments) ? agencyData.segments : [];
        // transform and return the data.
        return segmentData.reduce((agg, item) => {
            // item.segmentType  = 'LOCATION' | 'OFFICE' | 'UNIT_GROUP' | 'UNIT_SIZE' | 'UNIT'
            // FILTERS_ARRAY key = 'LOCATION' | 'OFFICE' | 'UNITGROUP' | 'UNITSIZE' | 'UNIT'
            // so we remove the '_' char, if there, to match between the two
            const itemSegmentType = item.segmentType.replace(/_/g, '');

            // If we recognize the segmentType
            if (itemSegmentType in FILTERS_ARRAY) {
                // the VALUE is a string that is a key in both `item` and the output object
                const segmentKey = FILTERS_ARRAY[itemSegmentType].VALUE;
                // seg is the the actual segment data from item for the given segment type
                const seg = item[segmentKey];
                // ensure we have an array for this segmentType in the output object.
                agg[segmentKey] ??= [];

                // sanitize, manipulate, and add the different segment types to the output object.
                switch (segmentKey) {
                    case FILTERS_ARRAY.LOCATION.VALUE:
                        // for location segments, we use the `${city}, ${province}` format, if safe.
                        if (`${seg.city}${seg.province}`.search(FULL) === -1) {
                            agg[segmentKey].push(`${seg.city}, ${seg.province}`);
                        }
                        break;
                    case FILTERS_ARRAY.UNIT.VALUE:
                        // for unit segments, we use the `${name} (${unitCode})-${propertyId}` format, if safe.
                        if (`${seg.name}${seg.unitCode}${seg.propertyId}`.search(ALLOW_KEYBOARD_SPECIAL_CHARS) === -1) {
                            agg[segmentKey].push(`${seg.name} (${seg.unitCode})-${seg.propertyId}`);
                        }
                        break;
                    default:
                        // all other segments use the value of the segment...
                        // we have to coerce to string because some values (UNIT_SIZE) are numeric
                        if (`${seg}`.search(ALLOW_KEYBOARD_SPECIAL_CHARS) === -1) {
                            agg[segmentKey].push(`${seg}`);
                        }
                        break;
                }
            }
            return agg;
        }, {});
    }

    public async reduceAllAgencyData(store: Partial<AssistantStore> = this) {
        await this.fetchAllAgencyData((data) => {
            const segments = this.reduceAgencySegments(data.agency);
            deepAssign(store, {platform: {agencyData: {...data.agency, segments}, allAgencies: data.getAllAgencies}});
        }).catch(this.logError('allAgencyData'));
    }
}

function encode(values) {
    return Object.entries(values || {})
        .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
        .join('&');
}

function decode(cookie) {
    return Object.fromEntries(
        (cookie || '').split('&').map((pair) =>
            pair.split('=').map((v) => {
                let val = decodeURIComponent(v);
                try {
                    // try to covert type using JSON.parse()
                    val = JSON.parse(decodeURIComponent(v));
                    // eslint-disable-next-line no-empty
                } catch (err) {}
                return val;
            })
        )
    );
}

module.exports = {
    encode,
    decode
};

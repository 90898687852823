import React from 'react';

export type ScriptTagProps = {
    script: string;
    id?: string;
};

export function ScriptTag({script, id = Math.random().toString()}: ScriptTagProps) {
    return (
        <script
            type="text/javascript"
            id={id}
            suppressHydrationWarning
            dangerouslySetInnerHTML={{
                __html: `${script}`
            }}
        ></script>
    );
}

import type {ContextFetchOptions, ContextPluginOptions, ContextResult} from 'bernie-plugin-context';

export interface AdvancedContextPluginOptions extends ContextPluginOptions {
    appProtocolAndHost: string;
}

export default async (options: ContextFetchOptions<AdvancedContextPluginOptions>): Promise<ContextResult> => {
    const {getPlatformContext} = await import('bernie-plugin-context');
    const {parse} = await import('url');
    let parsedUrl;
    if (options?.request?.serverRequest?.parsedUrl) {
        parsedUrl = options.request.serverRequest.parsedUrl;
    }

    try {
        options.request.serverRequest.parsedUrl = parse(
            `${options.options.appProtocolAndHost}${options.request.serverRequest.url}`,
            true
        );
        return getPlatformContext(options);
    } catch (e) {
        console.log(`Error Fetching context: ${e.toString()}`);
    } finally {
        if (parsedUrl) {
            options.request.serverRequest.parsedUrl = parsedUrl;
        }
    }
};

import React from 'react';

export const EDAP: React.FC = () => {
    return (
        <script
            type="text/javascript"
            async
            src="https://csvcus.homeaway.com/rsrcs-crs/edap-integrations/10.6.0/javascripts/edap-integrations.min.js"
            crossOrigin="anonymous"
        ></script>
    );
};
export default EDAP;

import React from 'react';
import {ScriptTag} from './ScriptTag';

export type AppEnvironment = 'dev' | 'unit' | 'test' | 'stage' | 'production';
export type MonikerBrand =
    | 'odhr'
    | 'homeaway_de'
    | 'homeaway_us'
    | 'homeaway_uk'
    | 'homeaway_es'
    | 'homeaway_cafr'
    | 'homeaway_br'
    | 'homeaway_ie'
    | 'homeaway_it'
    | 'homeaway_pt'
    | 'homeaway_nl'
    | 'homeaway_se'
    | 'homeaway_no'
    | 'homeaway_dk'
    | 'homeaway_fi'
    | 'homeaway_au'
    | 'homeaway_ca'
    | 'homeaway_mx'
    | 'abritel'
    | 'vrbo'
    | 'vr'
    | 'homeaway_at'
    | 'homeaway_in'
    | 'homeaway_ar'
    | 'homeaway_co'
    | 'homelidays_fr'
    | 'homelidays_es'
    | 'homelidays_it'
    | 'homeaway_nz'
    | 'toprural'
    | 'homeaway_ru'
    | 'homeaway_pl'
    | 'homeaway_tr'
    | 'bnb'
    | 'bookabach'
    | 'travelmob'
    | 'stayz'
    | 'yesbookit'
    | 'homeaway_gr'
    | 'luxury_us'
    | 'luxury_uk'
    | 'luxury_de'
    | 'affiliate'
    | 'homeaway_cn'
    | 'homeaway_hk'
    | 'homeaway_id'
    | 'homeaway_jp'
    | 'homeaway_kr'
    | 'homeaway_lk'
    | 'homeaway_my'
    | 'homeaway_ph'
    | 'homeaway_sg'
    | 'homeaway_th'
    | 'homeaway_tw'
    | 'homeaway_vn'
    | 'homeaway_ec'
    | 'homeaway_uy'
    | 'homeaway_pe'
    | 'homeaway_cl'
    | 'homeaway_asia'
    | 'homeaway_ae'
    | 'travelmob_uk'
    | 'travelmob_au'
    | 'travelmob_ca'
    | 'travelmob_fr'
    | 'travelmob_es'
    | 'travelmob_it'
    | 'travelmob_de'
    | 'bnb_de'
    | 'bnb_es'
    | 'bnb_fr'
    | 'bnb_it'
    | 'escapia'
    | 'homeaway_help'
    | 'hasp_escapia'
    | 'hasp_v12'
    | 'rezovation'
    | 'vacationprotection'
    | 'toprural_cat'
    | 'toprural_it'
    | 'toprural_fr'
    | 'toprural_pt';
export type AnalyticsBrand =
    | MonikerBrand
    | 'admin_homeaway_us'
    | 'asia_homeaway_us'
    | 'expedia_abritel'
    | 'expedia_homeaway_de'
    | 'expedia_homeaway_it'
    | 'expedia_homeaway_us'
    | 'expediagroup'
    | 'mobile_ios'
    | 'mobile_android'
    | -1;
export type PageFlow =
    | 'ppb signup'
    | 'ppb new property'
    | 'pps signup'
    | 'pps new property'
    | 'pps renewal'
    | 'ppb renewal'
    | 'pps addto'
    | 'inquiry'
    | 'booking'
    | 'property review'
    | -1;
export type PageType =
    | 'home'
    | 'search'
    | 'property'
    | 'booking'
    | 'booking payment'
    | 'inquiry'
    | 'cart'
    | 'error'
    | 'lyp'
    | 'marketing'
    | 'travel-ideas'
    | 'careers'
    | 'ums'
    | 'ownerdash'
    | 'pmdash'
    | 'travelerdash'
    | 'listing management'
    | 'payment setup'
    | 'platform-android'
    | 'platform-ios'
    | 'platform-web'
    | 'reviews'
    | 'ppb'
    | 'prn'
    | 'favorites'
    | 'traveler profile'
    | 'my trips'
    | 'traveler protection'
    | 'rental assurance'
    | 'search landing page'
    | 'partner api'
    | 'mobile'
    | 'tripboards'
    | 'merchandising landing page'
    | 'interstitial'
    | 'internal tools'
    | 'virtual tours'
    | 'escapiadash';
export type VisitorType = 'owner' | 'traveler' | 'csr' | 'sales' | 'property manager' | 'internal' | -1;

export interface AnalyticsDataLayerOptions {
    appenvironment: AppEnvironment;
    appname: string;
    appversion: string;
    monikerbrand: MonikerBrand;
    mpaasregion: string | -1;
    pageflow: PageFlow;
    pagename: string;
    pagetype: string;
    publicuuid: string | -1;
    sensitive: boolean;
    visitortype: VisitorType;
    analyticsbrand?: string;
    displaylocale?: string | -1;
}

export const AnalyticsDataLayer: React.FC<Partial<AnalyticsDataLayerOptions>> = (
    options: Partial<AnalyticsDataLayerOptions>
) => {
    return (
        <ScriptTag
            id="analyticsDataLayer"
            script={`
        window['analyticsdatalayer'] = {
            appversion: '${options.appversion}',
            publicuuid: '${options.publicuuid}',
            monikerbrand: '${options.monikerbrand}',
            analyticsbrand: '${options.analyticsbrand}',
            appname: '${options.appname}',
            appenvironment: '${options.appenvironment}',
            pagetype: '${options.pagetype}',
            pageflow: '${options.pageflow}',
            pagename: '${options.pagename}',
            visitortype: '${options.visitortype}',
            sensitive: '${options.sensitive}',
            mpaasregion: '${options.mpaasregion}',
            displaylocale: '${options.displaylocale}'
        };
    `}
        />
    );
};

export default AnalyticsDataLayer;

import React from 'react';
import {ScriptTag} from './ScriptTag';

export type MunchkinProps = {
    apiKey: string;
};

export const Munchkin = (props: MunchkinProps) => (
    <ScriptTag
        id="munchkin"
        script={`
        (function () {
            var didInit = false;
            function initMunchkin() {
                if (didInit === false) {
                    didInit = true;
                    Munchkin.init('${props.apiKey}');
                }
            }
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//munchkin.marketo.net/munchkin.js';
            s.onreadystatechange = function () {
                if (this.readyState == 'complete' || this.readyState == 'loaded') {
                    initMunchkin();
                }
            };
            s.onload = initMunchkin;
            document.getElementsByTagName('head')[0].appendChild(s);
        })();
    `}
    />
);

export default Munchkin;

import {L10nModule} from 'bernie-l10n';

export const localization = (locale: string): L10nModule => {
    const mod = new L10nModule();

    mod.current = locale;
    mod.globalize = require(`escapia-bernie-experience-localization/dist/bundle-${locale}`);

    return mod;
};

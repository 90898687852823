import type {Controller, Result, FetchOptions} from 'bernie-core';
import type {AdvancedController, AdvancedOptions} from '@escapia/bernie-plugin-advanced-controller';
import {AssistantStore} from 'src/stores';
import type {PageData} from 'bernie-http';
import {codeSplit} from 'bernie-core';
import get from 'lodash/get';
import {PATHS, ROLE_CHECK, ESCAPIA_HOUSEKEEPING_ROLES} from '../shared/constants';
import intersection from 'lodash/intersection';
import React from 'react';

// head components
import {AnalyticsDataLayer, AppEnvironment} from 'src/components/analyticsDataLayer';
import {EdapOptions} from '../components/edapOptions';
import {EDAP} from '../components/edap';
import {StabAnalyticsPolyfill} from '../components/stabAnalyticsPolyfill';
import {Munchkin} from '../components/munchkin';
import {Pendo} from '../components/pendo';
import {Qualtrics} from '../components/qualtrics';
import {ScriptTag} from 'src/components/ScriptTag';

export class AppController implements Controller, AdvancedController {
    public pageId = 'app';
    public path = '/:prefix?/p/*';
    public routeName = 'app';
    public bundles = [];

    /**
     * Components may be referred to directly
     *   `public component = ExamplePage;`
     *
     * Or, the preferred option is to create a code split point that will generate a new bundle.
     *   Use webpack's magic comments `webpackChunkName: "INSERT_FRIENDLY_NAME_HERE"`
     *   This will give it a friendly name for the name of the file.
     */
    public component = codeSplit(() => import(/* webpackChunkName: "assistant" */ '../views/app'));
    public exact = true;

    public async fetch(options: FetchOptions): Promise<Result> {
        const {request, response} = options;
        const {escapiaRoles} = get(request, 'extensions.auth.artifacts.credentials.principal', {});
        const prefix = request.params?.prefix;
        const route = get(request, 'query.route') || request.path;

        // Code relating to 7120 is rotten code and is recommended to ignore
        // For the 7120 agency should be available just functional of the partner admin.
        // if (agencyId === 7120 && route !== PATHS.P_ADMIN) {
        //   response.redirect(307, PATHS.P_ADMIN);
        //   return Promise.resolve({});
        // }

        const {stores} = options;
        const store = stores.get<AssistantStore>('assistant');

        // Make sure the user is allowed to see the dashboard as part of the login workflow only.
        // Under any other use case, permissions should be managed client side with pre-existing mechanisms.
        // This handles dashboard permissions for any role that is NOT allowed to see the perf dash.
        const sessionCheckResponse = await store.fetchSessionCheckResponse();
        const referer = get(request, 'headers.referer', '');
        if (sessionCheckResponse === ROLE_CHECK.BLOCKED) {
            if (referer.indexOf(PATHS.LOGIN) > -1) {
                if (response && intersection(escapiaRoles, ESCAPIA_HOUSEKEEPING_ROLES).length > 0) {
                    const housekeeperPath = prefix
                        ? `/${prefix}${PATHS.ESCAPIA_HOUSEKEEPER_HOME}`
                        : PATHS.ESCAPIA_HOUSEKEEPER_HOME;
                    response.redirect(302, housekeeperPath);
                    return {};
                }
            }

            // Check to see if the request came from classic escapia (OR)
            // VPASSIST-3739: After login, classic escapia redirects user to customer home or dashboard directly.
            // In such cases, there is no '.aspx' in the 'referer'
            // Hence if the blocked route is PATHS.DASH, redirecting users to ESCAPIA_HOME
            if (response && (referer.indexOf('.aspx') > -1 || route.indexOf(PATHS.DASH) > -1)) {
                const escapiaHomePath = prefix ? `/${prefix}${PATHS.ESCAPIA_HOME}` : PATHS.ESCAPIA_HOME;
                response.redirect(302, escapiaHomePath);
                return {};
            }
        }
        return {};
    }

    public async loadData({stores}: AdvancedOptions) {
        const store = stores.get<AssistantStore>('assistant');
        return store.fetchInitialState();
    }

    public getHeadComponents({request, stores}: AdvancedOptions) {
        const store = stores.get<AssistantStore>('assistant') as AssistantStore;
        const platform = store.platform;
        return [
            () => (
                <AnalyticsDataLayer
                    appversion={store.system?.appVersion}
                    publicuuid={-1}
                    monikerbrand={'hasp_escapia'}
                    analyticsbrand={'hasp_escapia'}
                    appname={'escapia-bernie-experience'}
                    appenvironment={store.system.environment as AppEnvironment}
                    pagetype={'escapiadash'}
                    pageflow={-1}
                    pagename={request.url}
                    visitortype={'property manager'}
                    sensitive={false}
                    mpaasregion={-1}
                    displaylocale={store.system?.locale ?? 'en_US'}
                />
            ),
            () => <EdapOptions skipPageview skipTagManager />,
            () => <EDAP />,
            () => <StabAnalyticsPolyfill />,
            () => (
                <Pendo
                    publicUuid={platform?.userData?.publicUuid}
                    host={platform?.agencyData?.hostname?.split('.')?.[0]}
                    roles={platform?.userData?.escapiaRoles?.toString()}
                    agencyId={platform?.agencyData?.agencyId?.toString()}
                    location={
                        platform?.agencyData?.settings?.Language?.value?.length > 4
                            ? store
                                  .getCountryLocaleMap()
                                  .getCountryNameByAlpha2(
                                      platform?.agencyData?.settings?.Language?.value.substring(3, 5)
                                  )
                            : ''
                    }
                    apiKey="43e10ef5-9370-41c0-4c5b-3799ea29930e"
                />
            ),
            () => <Munchkin apiKey="505-LZM-970" />,
            () => <Qualtrics projectId="ZN_d42wgiqENuNioMm" account="expediaxm" addDiv={false} />,
            () => (
                <ScriptTag
                    id="csrf"
                    script={`
                    window.ha = window.ha || {};
                    window.errorReporterConfig = window.errorReporterConfig || {url: '/react/errors'};
                    window.ha.csrfToken = ${JSON.stringify(
                        request?.extensions?.plugins?.['@catalyst/hapi-jwt-csrf']?.jwtCsrfTokens?.header ?? ''
                    )};`}
                />
            )
        ];
    }

    public fetchPageData(): Promise<PageData> {
        return Promise.resolve({
            title: 'Escapia',
            pageName: 'app'
        }) as Promise<PageData>;
    }
}

/**
 *
 * @param request
 * @return {*|boolean}
 */
function isAuthStrategyOkta(request) {
    return request.auth && request.auth.isAuthenticated && request.auth.strategy === 'okta';
}

/**
 *
 * @param request
 * @return {*|boolean}
 */
function isAuthStrategyHomeaway(request) {
    return request.auth && request.auth.isAuthenticated && request.auth.strategy === 'homeaway';
}

module.exports = {
    isAuthStrategyOkta,
    isAuthStrategyHomeaway
};
